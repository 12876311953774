import React, {useCallback, useMemo} from "react";
import {Marker, useMap} from "react-leaflet";
import L from "leaflet";
import "tailwindcss/tailwind.css";
import {useDispatch, useSelector} from "react-redux";
import {showModal} from "../../../store/markers";
import {useEventHandlers} from "@react-leaflet/core";

export default function CustomMarker(props) {

    const dispatch = useDispatch();

    const markerData = useSelector(state => state.markers.markerData);

    const shouldShowModal = useSelector(state => state.markers.showModal);

    const parentMap = useMap();

    const onChange = useCallback(() => {

    }, [parentMap])

    // Listen to events on the parent map
    const handlers = useMemo(() => ({move: onChange, zoom: onChange}), []);

    useEventHandlers({instance: parentMap}, handlers);

    function getMarkerSize(zoom) {
        return new L.Point(60, 60);
    }

    let iconUrl = markerData.id === props.marker.id && shouldShowModal ? props.marker.icons.active.url : props.marker.icons.default.url;

    return <Marker position={props.marker.location.fictional_coordinates}
                   eventHandlers={{
                       click: (e) => {
                           dispatch(showModal(props.marker))
                       },
                   }}
                   icon={new L.Icon({
                       iconUrl: iconUrl,
                       iconRetinaUrl: iconUrl,
                       iconAnchor: null,
                       popupAnchor: [0, 0],
                       shadowUrl: null,
                       shadowSize: null,
                       shadowAnchor: null,
                       iconSize: getMarkerSize(parentMap.getZoom()),
                       className: 'leaflet-div-icon',
                   })}>
    </Marker>;
}