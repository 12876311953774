import {SRLWrapper} from "simple-react-lightbox";
import React from "react";
import {useDispatch} from "react-redux";
import {setLightboxOpened} from "../../../store/markers";

export function MainImage(props) {

    const dispatch = useDispatch();

    const callbacks = {
        onLightboxOpened: object => dispatch(setLightboxOpened(true)),
        onLightboxClosed: object => {dispatch(setLightboxOpened(false))},
    };

    return <div>
        <SRLWrapper
            callbacks={callbacks}
            options={{
                settings: {
                    disableKeyboardControls: true
                },
                caption: {},
                buttons: {
                    showAutoplayButton: false,
                    showDownloadButton: false,
                    showNextButton: false,
                    showPrevButton: false,
                },
                thumbnails: {
                    showThumbnails: false
                },
                progressBar: {},
            }}>
            <img
                className="absolute h-full w-full object-cover"
                src={props.markerData.image.url}
                alt=""
            />
        </SRLWrapper>

        {props.markerData.drawer_image.url ? <img
            className="absolute h-full w-full object-cover"
            src={props.markerData.drawer_image.url}
            alt=""
        /> : <img
            className="absolute h-full w-full object-cover"
            src={props.markerData.image.url}
            alt=""
        />}
    </div>;
}