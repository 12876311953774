import axios from "axios";

export default class FolkTaleService {

    getPublishedFolkTales() {
        return axios.get(process.env.REACT_APP_API_URL + 'wl/v1/legendary-locations');
    }

    getPreviewFolkTales() {
        return axios.get(process.env.REACT_APP_API_URL + 'wl/v1/legendary-locations?preview');
    }
}