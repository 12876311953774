import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    markers: [],
    showSplashScreen: true,
    generalTexts: {
        help: {}
    },
    markerData: false,
    markersLoaded: false,
    showModal: false,
    showMobileMap: false,
    lightboxOpened: false,
    informationModalOpen: false
}

const slice = createSlice({
    name: 'markers',
    initialState: initialState,
    reducers: {
        setInformationModalOpenSuccess: (state, action) => {
            state.informationModalOpen = action.payload;
        },
        hideSplashScreenSuccess: (state) => {
            state.showSplashScreen = false;
        },
        showModalSuccess: (state, action) => {
            state.showModal = true;
            state.markerData = action.payload;
        },
        hideModalSuccess: (state, action) => {
            state.showModal = false;
        },
        setMarkersSuccess: (state, action) => {
            state.generalTexts = action.payload["general"];
            state.markers = action.payload["tales"];
            state.markerData = action.payload["tales"][0];
            state.markersLoaded = true;
            // document.getElementById('drawer').scrollTop = 0;
        },
        setShowMobileMapSuccess: (state, action) => {
            state.showMobileMap = action.payload;
        },
        setLightboxOpenedSuccess: (state, action) => {
            state.lightboxOpened = action.payload;
        },
    },
});

export default slice.reducer;

export const {showModalSuccess, hideModalSuccess, setMarkersSuccess, setShowMobileMapSuccess, setLightboxOpenedSuccess, hideSplashScreenSuccess, setInformationModalOpenSuccess} = slice.actions

export const setInformationModalOpen= (open) => async dispatch => {
    try {
        dispatch(setInformationModalOpenSuccess(open));
    } catch (e) {
        return console.error(e.message);
    }
}

export const hideSplashScreen = () => async dispatch => {
    try {
        dispatch(hideSplashScreenSuccess());
    } catch (e) {
        return console.error(e.message);
    }
}

export const showModal = (markerData) => async dispatch => {
    try {
        dispatch(showModalSuccess(markerData));
    } catch (e) {
        return console.error(e.message);
    }
}

export const setMarkers = (data) => async dispatch => {
    try {
        dispatch(setMarkersSuccess(data));
    } catch (e) {
        return console.error(e.message);
    }
}

export const hideModal = () => async dispatch => {
    try {
        dispatch(hideModalSuccess())
    } catch (e) {
        return console.error(e.message);
    }
}

export const setShowMobileMap = (bool) => async dispatch => {
    try {
        dispatch(setShowMobileMapSuccess(bool));
    } catch (e) {
        return console.error(e.message);
    }
}

export const setLightboxOpened = (bool) => async dispatch => {
    try {
        dispatch(setLightboxOpenedSuccess(bool));
    } catch (e) {
        return console.error(e.message);
    }
}