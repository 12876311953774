import React from "react";
import Drawer from "./Drawer";
import {Hero} from "./Hero/Hero";
import {Legend} from "./Legend/Legend";
import Exercises from "./Exercises/Exercises";
import {Facts} from "./Facts/Facts";
import {MapContent} from "./Map/MapContent";
import About from "./About/About";

export function TaleDrawer() {
    return <Drawer>
        <Hero/>

        <div className="px-0 pb-6">
            <Legend/>

            <Facts/>

            <Exercises/>

            <About/>

            <MapContent/>
        </div>
    </Drawer>;
}