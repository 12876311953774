import {Disclosure} from '@headlessui/react'
import React from "react";
import {DrawerContent} from "../../Shared/DrawerContent";
import {useSelector} from "react-redux";
import {FileRow} from "../../Shared/Files/FileRow";
import {FileList} from "../../Shared/Files/FileList";
import {Divider} from "../../Shared/Divider";
import {Subtitle} from "../../Shared/Typography/Subtitle";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";

export default function Exercises() {
    const markerData = useSelector(state => state.markers.markerData);

    if (markerData.exercises.length === 0) {
        return '';
    }

    return (
        <DrawerContent className="mb-8">
            <div className="mb-2 px-4">
                <Subtitle>Övningar</Subtitle>
            </div>
            <div className="bg-white" style={{
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
                borderRadius: '6px 6px 0px 0px'
            }}>
                {
                    markerData.exercises.map((exercise, index) => {
                        return (
                            <Disclosure key={index}>
                                {({open}) => (
                                    <div className="border-sm rounded bg-white">
                                        <Disclosure.Button className="py-3 w-full px-4">
                                            <div className="flex items-center justify-between">
                                                <div className="flex">
                                                    <span className="accordion__number mr-3">{index + 1}. </span>
                                                    <span className="accordion__title">{exercise.title}</span>
                                                </div>
                                                {open ? <ChevronUpIcon className="accordion__icon h-4 w-4"/> :
                                                    <ChevronDownIcon className="accordion__icon h-4 w-4"/>}
                                            </div>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-gray-500 mt-1 pb-4">
                                            <div className="px-4 mb-4">
                                                <div className="text-sm text-black"
                                                     dangerouslySetInnerHTML={{__html: exercise.text}}/>
                                            </div>
                                            {exercise.file ?
                                                <div>
                                                    <Divider/>
                                                    <div className="mt-4 px-4">
                                                        <h5 className="drawer__section-title mb-2">Extramaterial</h5>
                                                        <FileList title="Extramaterial">
                                                            <FileRow name={exercise.file.filename}
                                                                     url={exercise.file.url}/>
                                                        </FileList>
                                                    </div>
                                                </div> : null}
                                        </Disclosure.Panel>
                                        <Divider/>
                                    </div>)}
                            </Disclosure>)
                    })
                }
            </div>
        </DrawerContent>
    );
}