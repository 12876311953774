import {useSelector} from "react-redux";
import {MainImage} from "./MainImage";
import React, {useState} from "react";
import {PlayIcon} from "@heroicons/react/solid";
import Modal from "./Modal";
import {useLightbox} from "simple-react-lightbox";
import {ExpandIcon} from "./ExpandIcon";

export function Hero() {
    const legend = useSelector(state => state.markers.markerData);

    const [open, setOpen] = useState(false);
    const {openLightbox} = useLightbox();

    return <div className={"relative h-40 sm:h-56 " + (legend.video_embed ? 'mb-14' : 'mb-6')}>

        {legend.video_embed ?
            <Modal open={open} setOpen={setOpen} videoEmbed={legend.video_embed}/> : ''
        }

        <MainImage markerData={legend}/>

        <div className="inset-0 absolute image__overlay" onClick={() => openLightbox()}>
            <div className="hero__expand-icon absolute left-4 top-3 w-10 h-10 flex items-center justify-center">
                <ExpandIcon/>
            </div>
            <div
                className={"flex items-center justify-between absolute left-5 text-white " + (legend.video_embed ? 'bottom-10' : 'bottom-4')}>
                <div className="flex">
                    <img className="w-14 h-14 mr-2 object-contain"
                         src={legend.icons.default.url}
                         alt=""/>
                </div>
            </div>
        </div>
        {legend.video_embed ?
            <div
                onClick={() => setOpen(!open)}
                className="watch__video-button flex justify-between items-center mx-8 bg-white h-12 pl-6 pr-1 rounded-full absolute -bottom-5 left-0 right-0 cursor-pointer">
                <span className="watch__video-text">Se video</span>
                <PlayIcon className="watch__video-icon h-10 w-10"/>
            </div> : ''}
    </div>;
}