import {useDispatch} from "react-redux";
import {hideModal} from "../../store/markers";
import {XIcon} from "@heroicons/react/outline";
import React from "react";

export function CloseDrawerButton() {
    const dispatch = useDispatch();

    return <button
        data-testid="close-button"
        type="button"
        className="flex py-1 items-center absolute right-3 top-3 z-50 bg-gray-600 rounded-full text-gray-300 hover:text-gray-500 focus:outline-none pl-3 pr-2"
        onClick={() => dispatch(hideModal())}>
        <span className="mr-1 close-button__text">Stäng</span>
        <XIcon className="h-4 w-4" aria-hidden="true"/>
    </button>;
}