import React from "react";
import GoogleMaps from "./GoogleMaps";
import {Title} from "../../Shared/Typography/Title";
import {DrawerContent} from "../../Shared/DrawerContent";

export function MapContent() {
    return <DrawerContent>
        <Title className="px-4 mb-2">Hitta hit</Title>
        <GoogleMaps/>
    </DrawerContent>
}