import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import markers from './markers'
const reducer = combineReducers({
    markers
    // here we will be adding reducers
})
const store = configureStore({
    reducer,
})


export default store;