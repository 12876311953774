import '../../App.css';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import "tailwindcss/tailwind.css";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../scss/main.scss";
import LeafletMap from "./LeafletMap";
import {TaleDrawer} from "../Drawer/TaleDrawer";
import {setInformationModalOpen, setMarkers} from "../../store/markers";
import {SplashScreen} from "../SplashScreen";
import * as PropTypes from "prop-types";
import {Logo} from "../Logo";
import HelpModal from "../Shared/HelpModal";
import HelpButton from "../Shared/HelpButton";

function MainMap({fetchData}) {

    if (process.env.REACT_APP_NODE_ENV === "local") {
        makeServer({environment: "development"})
    }

    const dispatch = useDispatch();

    useEffect(() => {
        fetchData().then(response => {
            dispatch(setMarkers(response.data));
        });
    }, []);


    const lightboxOpened = useSelector(state => state.markers.lightboxOpened);

    const escFunction = useCallback((event) => {

        if (event.keyCode === 27) {
            //Do whatever when esc is pressed

            if (!lightboxOpened) {
                dispatch(hideModal());
            }
        }
    }, [lightboxOpened]);

    const showSplashScreen =  useSelector(state => state.markers.showSplashScreen);

    return (
        <div className="App relative">
            <Logo/>
            <HelpModal/>
            <HelpButton/>
            <SplashScreen/>

            <LeafletMap/>
            <TaleDrawer/>
        </div>
    )
}

export default MainMap;
