import React from "react";
import {Title} from "./Typography/Title";

export function Sources(props) {
    return <div className={props.className}>
        <Title>Källor och mer att läsa</Title>
        <ul className="mt-2">
            {props.children}
        </ul>
    </div>;
}