import React from "react";

export function ExpandIcon() {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1H19V7" stroke="white" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M7 19H1V13" stroke="white" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M19 1L12 8" stroke="white" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M1 19L8 12" stroke="white" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>;
}