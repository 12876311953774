import React from "react";
import {Marker} from "react-leaflet";
import L from "leaflet";
import "tailwindcss/tailwind.css";

export default function MinimapMarker(props) {
    return <Marker
        position={[props.marker.location.fictional_coordinates.lat, props.marker.location.fictional_coordinates.lng]}
        icon={new L.Icon({
            iconUrl: props.marker.icons.default.url,
            iconRetinaUrl: props.marker.icons.active.url,
            iconAnchor: null,
            popupAnchor: [0, 0],
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(16, 16),
            className: 'leaflet-div-icon'
        })}>
    </Marker>
}