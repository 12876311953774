import {ChevronRightIcon} from "@heroicons/react/outline";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setInformationModalOpen} from "../store/markers";

export function Logo() {
    const [fadeIn, setFadeIn] = useState(false);

    const dispatch = useDispatch();

    const markersLoaded = useSelector(state => state.markers.markersLoaded);

    const showSplashScreen = useSelector(state => state.markers.showSplashScreen);

    function fadeOutSplashScreen() {
        setTimeout(() => {
            setFadeIn(true);
        }, 200);
    }

    useEffect(() => {
        if (!showSplashScreen) {
            fadeOutSplashScreen();
        }
    }, [showSplashScreen]);

    return <div
        className={"absolute top-4 px-4 mx-auto sm:px-0 sm:left-4 sm:top-10 sm:left-10 z-10 cursor-pointer high-z-index w-full sm:w-auto " + (fadeIn ? 'fade-in' : '')}>

        <div onClick={() => dispatch(setInformationModalOpen(true))} className={"logo-container opacity-0 max-w-max w-100 sm:w-auto mx-auto " + (fadeIn ? 'fade-in' : '')} style={{background: 'rgba(144, 106, 101, 0.4)'}}>
            <object className="mb-4 logo mx-auto" data="/images/logo_new_2022.svg" style={{width: 300, maxWidth: '100%', pointerEvents: 'none'}} type="image/svg+xml"></object>
        </div>
    </div>;
}