import {useSelector} from "react-redux";
import React from "react";
import L, {LatLngBounds} from "leaflet";
import MinimapMarker from "./Markers/MinimapMarker";
import {MapIcon} from "@heroicons/react/outline";
import {FeatureGroup} from "react-leaflet";

const {MapContainer, TileLayer, useMap, useMapEvent, Rectangle} = require("react-leaflet");
const {useEventHandlers} = require('@react-leaflet/core');
const {useCallback, useState, useMemo} = require("react");

const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
        html: `<div class="font-playfair text-sm pb-2" style="background: #3C9CA5; height: 20px; width: 20px; display: flex; align-items: center; justify-content: center; border-radius: 50px; color: #fff;">${cluster.getChildCount()}</div>`,
        className: 'marker-cluster-custom',
        iconSize: L.point(8, 8, true),
    });
}

function MinimapBounds({parentMap, zoom}) {
    const minimap = useMap()

    // Clicking a point on the minimap sets the parent's map center
    const onClick = useCallback(
        (e) => {
            parentMap.setView(e.latlng, parentMap.getZoom())
        },
        [parentMap],
    )

    useMapEvent('click', onClick)

    // Keep track of bounds in state to trigger renders
    const [bounds, setBounds] = useState(parentMap.getBounds())
    const onChange = useCallback(() => {
        setBounds(parentMap.getBounds())
        // Update the minimap's view to match the parent map's center and zoom
        // minimap.setView(parentMap.getCenter(), zoom)
    }, [minimap, parentMap, zoom])

    // Listen to events on the parent map
    const handlers = useMemo(() => ({move: onChange, zoom: onChange}), [])

    useEventHandlers({instance: parentMap}, handlers)

    return <Rectangle bounds={bounds} pathOptions={{weight: 1}}/>
}

export default function MinimapControl({position, zoom}) {
    const parentMap = useMap()
    const mapZoom = zoom || 0

    const bounds = new LatLngBounds([-800, -180], [36, -50])

    const markers = useSelector(state => state.markers.markers);

    const showMobileMap = useSelector(state => state.markers.showMobileMap);

    const minimap = useMemo(
        () => (
            <div>
                <MapContainer
                    style={{height: 300, width: 180}}
                    attributionControl={false}
                    center={[0, -80]} zoom={1} scrollWheelZoom={true}
                    bounds={bounds}
                    zoomControl={false}
                    maxBounds={bounds}
                    dragging={false}
                    maxBoundsViscosity={1.0}
                >
                    <TileLayer
                        url="/tiled/{z}/{x}/{y}.png"
                        noWrap={true}
                        tms={true}
                        minZoom={1}
                        maxZoom={1}
                    >
                    </TileLayer>
                    <MinimapBounds parentMap={parentMap} zoom={1}/>

                    <FeatureGroup
                        iconCreateFunction={createClusterCustomIcon}
                        spiderfyOnMaxZoom={false}
                        showCoverageOnHover={false}
                        zoomToBoundsOnClick={false}>
                        {markers.map((item, i) => {
                            return <MinimapMarker marker={item} key={item.id}/>;
                        })}
                    </FeatureGroup>
                </MapContainer>
            </div>
        ),
        [markers],
    )

    return (
        <div className="leaflet-bottom leaflet-left">
            <div className="flex flex-col hidden md:block">
                <div className="leaflet-control leaflet-bar">{minimap}</div>
            </div>

            {/*<div className="flex flex-col md:mb-0 md:hidden">*/}
            {/*    {showMobileMap ? <div className="leaflet-control leaflet-bar bottom-24">{minimap}</div> : ''}*/}
            {/*</div>*/}
        </div>
    )
}