import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {hideSplashScreen} from "../store/markers";

export function SplashScreen() {
    const [delayed, setDelayed] = useState(false);

    const dispatch = useDispatch();

    const markersLoaded =  useSelector(state => state.markers.markersLoaded);
    const showSplashScreen =  useSelector(state => state.markers.showSplashScreen);

    function setMinimumDisplayTime() {
        setTimeout(() => {
            setDelayed(true);
        }, 3000);
    }

    setMinimumDisplayTime();

    useEffect(() => {
        if(markersLoaded && delayed){
            setTimeout(() => {
                dispatch(hideSplashScreen());
            }, 200);
        }

    }, [markersLoaded, delayed]);

    return <div className={"flex flex-col items-center min-h-screen justify-center absolute inset-0 z-10 transition high-z-index " + (showSplashScreen === false ? 'fade-out' : '')}>
        <div className="image__overlay inset-0 absolute" style={{background: "rgba(0,0,0,0.7)"}}></div>
        <div className="relative flex flex-col items-center min-h-screen justify-center">
            <h2 className="text-2xl text-white font-playfair">Sägenresan Sverige är framtagen av</h2>
            <img style={{maxWidth: '80%'}} src="/images/logo_white.svg" alt=""/>
        </div>

    </div>;
}