import React from "react";
import {DrawerContent} from "../../Shared/DrawerContent";
import {RichTextContent} from "../../Shared/Typography/RichTextContent";
import {useSelector} from "react-redux";

export function Legend() {
    const markerData = useSelector(state => state.markers.markerData);
    return <DrawerContent className="mb-8">
        <div className="px-4 mb-8">
            <h1 className="drawer__title" style={{color: '#121212'}}>{markerData.legend.title}</h1>
            <RichTextContent html={markerData.legend.text}/>
        </div>
    </DrawerContent>;
}