import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {Provider} from "react-redux";
import store from "./store/store";
import SimpleReactLightbox from 'simple-react-lightbox';
import {BrowserRouter} from "react-router-dom";

const path = require('path')
require('dotenv').config({path: path.resolve(__dirname, '../.env.local')})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SimpleReactLightbox style={{zIndex: 10000000000}}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </SimpleReactLightbox>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
