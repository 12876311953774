import {setInformationModalOpen} from "../../store/markers";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

export default function HelpButton() {

    const [fadeIn, setFadeIn] = useState(false);

    const showSplashScreen =  useSelector(state => state.markers.showSplashScreen);

    const dispatch = useDispatch();

    function fadeOutSplashScreen() {
        setTimeout(() => {
            setFadeIn(true);

            if(!localStorage.getItem('help_dialog_displayed')){
                dispatch(setInformationModalOpen(true));

                localStorage.setItem('help_dialog_displayed', 'true');
            }

        }, 200);
    }

    useEffect(() => {
        if(!showSplashScreen){
            fadeOutSplashScreen();
        }
    }, [showSplashScreen]);

    return (<button
        className={"absolute bottom-4 md:bottom-80 left-4 h-16 w-16 rounded-full text-white flex items-center justify-center font-playfair text-4xl cursor-pointer opacity-0 high-z-index " + (fadeIn ? 'fade-in' : '')}
        style={{background: '#006666'}}
        onClick={() => dispatch(setInformationModalOpen(true))}>
        i
    </button>);
}
