import React from "react";
import {DrawerContent} from "../../Shared/DrawerContent";
import {Title} from "../../Shared/Typography/Title";
import {RichTextContent} from "../../Shared/Typography/RichTextContent";
import {FileList} from "../../Shared/Files/FileList";
import {FileRow} from "../../Shared/Files/FileRow";
import {useSelector} from "react-redux";
import {SourceLink} from "../../Shared/SourceLink";
import {SourceText} from "../../Shared/SourceText";
import {Sources} from "../../Shared/Sources";

function getSourceComponent(source, index) {
    if (source.link) {
        return <SourceLink
            key={index}
            title={source.title}
            url={source.link}/>;
    }

    return <SourceText key={index} text={source.title}/>
}

export function Facts(props) {
    const markerData = useSelector(state => state.markers.markerData);

    return <DrawerContent className="mb-8">
        <div className="px-4">
            <h6 className="body-md">Fakta</h6>

            <RichTextContent html={markerData.facts.text}/>

            {markerData.facts.sources.length > 0 ?
                <Sources className="mt-8">
                    {
                        markerData.facts.sources.map(getSourceComponent)
                    }
                </Sources>
                : null}

            {markerData.facts.archives.length > 0 ?
                <div>
                    <Title className="mb-2 mt-8">Arkiv med mera</Title>

                    <FileList>
                        {markerData.facts.archives.map((archive, index) => {
                            if(archive.filename) return <FileRow key={index} name={archive.filename} url={archive.link} archive={archive}/>;
                        })}
                    </FileList>
                </div>
                : null}
        </div>
    </DrawerContent>
}