import {useMapEvents} from "react-leaflet";
import React, {useState} from "react";

export default function MapCoordinates() {

    const [coordinates, setCoordinates] = useState([0, 0]);

    const saveMarkers = (newMarkerCoords) => {
        setCoordinates(newMarkerCoords);
    };
    
    function MapClick({saveMarkers}) {
        useMapEvents({
            mousemove: (e) => {
                const {lat, lng} = e.latlng;
                saveMarkers([lat, lng]);
            }
        });

        return null;
    }

    return <div>
        <MapClick saveMarkers={saveMarkers}/>
        <div style={{
            position: 'absolute',
            'right': 0,
            'top': 0,
            zIndex: 100000,
            width: '80px',
            padding: '4px 8px',
            'background': '#1e1e1e',
            color: 'white'
        }}>Lat: {Math.round(coordinates[0])} <br/> Lng: {Math.round(coordinates[1])}
        </div>
    </div>;
}

