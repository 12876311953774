import React from "react";

export function FileList(props) {
    if (props.children.length === 0) {
        return <div></div>;
    }

    return <div className={props.className}>
        <ul role="list"
            className="file__row">
            {props.children}
        </ul>
    </div>;
}