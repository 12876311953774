import './App.css';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import "tailwindcss/tailwind.css";
import React, {useCallback, useEffect} from "react";
import "./scss/main.scss";
import {Route, Routes} from "react-router-dom";
import MainMap from "./components/Map/MainMap";
import FolkTaleService from "./services/FolkTaleService";

function App() {
    const folkTaleService = new FolkTaleService();

    return (
        <Routes>
            <Route path="/" element={<MainMap fetchData={folkTaleService.getPublishedFolkTales}/>} />
            <Route path="/preview" element={<MainMap fetchData={folkTaleService.getPreviewFolkTales}/>} />
        </Routes>
    )
}

export default App;
