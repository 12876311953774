import GoogleMapReact from 'google-map-react';
import {useSelector} from "react-redux";

const CustomMarker = (props) => <div><img style={{width: '40px', height: '40px'}} src={props.icon} alt=""/></div>;

export default function GoogleMaps() {

    const markerData = useSelector(state => state.markers.markerData);

    return <div style={{height: '300px', width: '100%', borderRadius: '6px', 'overflow': 'hidden'}}>
        <GoogleMapReact
            bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}
            center={markerData.location.actual_coordinates}
            defaultZoom={14}
        >
            <CustomMarker
                icon={markerData.icons.default.url}
                lat={markerData.location.actual_coordinates.lat}
                lng={markerData.location.actual_coordinates.lng}
                text="My Marker"
            />
        </GoogleMapReact>
    </div>;
}