import React, {Fragment} from 'react'
import {Transition} from '@headlessui/react'
import {useSelector} from "react-redux";
import {CloseDrawerButton} from "./CloseDrawerButton";

export default function Drawer(props) {

    const open = useSelector(state => state.markers.showModal);

    return (
        <Transition.Root show={open} as={Fragment}>
            <div>
                <div className="fixed inset-y-0 right-0 top-4 bottom-4 max-w-full flex"
                     style={{
                         zIndex: 100000000000,
                     }}>
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <div className="w-screen md:max-w-md">
                            <div className="relative ml-4 md:ml-0 mr-4 ">
                                <CloseDrawerButton/>
                            </div>
                            <div
                                className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll ml-4 md:ml-0 mr-4 relative"
                                style={{
                                    'background': '#F8F8F8', borderRadius: '12px',
                                    boxShadow: '0px 20px 56px rgba(0, 0, 0, 0.29)'
                                }}>
                                <div>
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Transition.Root>
    )
}