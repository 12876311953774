import {MapContainer, TileLayer, LayersControl} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster/src/react-leaflet-markercluster";
import CustomMarker from "./Markers/TaleMarker";
import MinimapControl from "./MinimapControl";
import MapCoordinates from "./MapCoordinates";
import React from "react";
import L, {LatLngBounds} from "leaflet";
import {useSelector} from "react-redux";
import {TaleDrawer} from "../Drawer/TaleDrawer";

export default function LeafletMap() {

    const createClusterCustomIcon = function (cluster) {
        return L.divIcon({
            html: `<div class="font-playfair text-2xl pb-2" style="background: #3C9CA5; height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; border-radius: 50px; color: #fff;">${cluster.getChildCount()}</div>`,
            className: 'marker-cluster-custom',
            iconSize: L.point(40, 40, true),
        });
    }

    const bounds = new LatLngBounds([-800, -180], [36, -50])

    const shouldDisplayCoordinates = window.location.href.indexOf("?debug") > -1;

    const markers = useSelector(state => state.markers.markers);


    return <MapContainer
        className="markercluster-map"
        attributionControl={false}
        center={[-80, -132]} zoom={5} scrollWheelZoom={true}
        bounds={bounds}
        zoomControl={false}
        maxBounds={bounds}
        maxBoundsViscosity={1.0}>

        <TileLayer
            url="/tiled/{z}/{x}/{y}.png"
            noWrap={true}
            tms={true}
            minZoom={5}
            maxZoom={5}
        >
        </TileLayer>

        <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
            {markers.map((item, i) => {
                return <CustomMarker marker={item} key={item.id}/>;
            })}
        </MarkerClusterGroup>

        {/*<CloudIcon lat={-76} lng={-137}/>*/}
        {/*<CloudIcon lat={-73} lng={-139}/>*/}
        {/*<CloudIcon lat={-73} lng={-145}/>*/}

        {/*<CloudIcon lat={-66} lng={-137}/>*/}
        {/*<CloudIcon lat={-63} lng={-139}/>*/}
        {/*<CloudIcon lat={-63} lng={-135}/>*/}

        {/*<CloudIcon lat={-46} lng={-117}/>*/}
        {/*<CloudIcon lat={-43} lng={-119}/>*/}
        {/*<CloudIcon lat={-43} lng={-115}/>*/}
        {/*<CloudIcon/>*/}


        <MinimapControl/>

        {shouldDisplayCoordinates ? <MapCoordinates/> : ''}
    </MapContainer>;
}