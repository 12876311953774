import {ArrowCircleDownIcon} from "@heroicons/react/outline";
import React from "react";

export function FileRow(props) {
    return <li
        className="px-4 py-3">
        <a href={props.url}
           data-testid="file-row-link-id"
           target="_blank"
           className="hover:text-blue-600 flex items-center justify-between">

            <span className="text-black body-sm">{props.name}</span>

            <ArrowCircleDownIcon
                className="flex-shrink-0 h-5 w-5 text-blue-400"
                aria-hidden="true"/>
        </a>
    </li>;
}